.ant-notification {
  top: 80px !important;
  width: 385px;
}

.uc-antd-notification .ant-notification-notice-message {
  font-weight: 600;
  font-size: 16px;
  color: #303030;
}

.uc-antd-notification .ant-notification-notice-description {
  font-size: 14px;
  color: #303030;
  line-height: 18px;
}
