.ql-tooltip {
  z-index: 10;
  left: 0 !important;
}

.ql-editor.ql-blank::before {
  font-style: normal;
  color: #dedede;
  font-weight: 300;
}

.ant-tooltip-inner {
  background-color: #000;
}