.auth0-lock .auth0-lock-header .auth0-lock-header-logo {
  width: 225px;
}

.auth0-lock.auth0-lock .auth0-lock-name {
  font-family: 'Avenir Next', Avenir, -apple-system, BlinkMacSystemFont, Roboto, Helvetica,
    sans-serif;
  text-rendering: optimizeLegibility;
  pointer-events: auto;
}
